<template>
  <div  class="post-edit-main">
    <el-drawer
      title="修改岗位"
      :visible.sync="drawers"
      :before-close="onClose"
      >
      <div class="form-class">
        <el-form 
        ref='form' 
        :model ='form'  
        label-position="top" 
        :rules="rules">
          <el-form-item label="岗位名称" prop='name'>
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="岗位编码" prop='code'>
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop='sort'>
            <el-input v-model="form.sort"></el-input>
          </el-form-item>
          <!-- 暂时不用状态功能 后端容易出问题 先写死 -->
          <!-- <el-form-item label="状态" >
            <el-radio-group v-model="form.status">
              <el-radio-button label="1">正常</el-radio-button>
              <el-radio-button label="0">停用</el-radio-button>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.remarks"></el-input>
          </el-form-item>
          <el-form-item class="bottom-btn">
            <el-button size="mini" @click="onClose">取消</el-button>
            <el-button size="mini" type="primary" @click='onSave'>保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { deepClone } from '@topology/core';
import { editPost } from '@/api/saasManage'
export default {
  name:'EditDrawer',
  props: {
    drawer:{
      type:Boolean,
      default:false,
    },
    editData:{
      type:Object,
      default:() => {}
    }
  },
  data() {
    return {
      drawers:false,
      form:{},
      rules:{
        code:[
           { required: true, message: '岗位编码不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '岗位名称不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    editData(newVal){
      if(newVal && newVal.id){
        this.form = deepClone(newVal);
      }
    }
  },
  methods: {
    /**
     * @desc:保存
     */
    onSave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          editPost(this.form,this.form.id).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('drawerSave');
          }).catch(() => {
            this.$message.error(`操作错误`);
          });
        }
      })

    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.drawers = false;
      this.$emit('drawerClose');
    },
  },
};
</script>
<style lang="less">
.post-edit-main{
  text-align:left;
  color: #444;
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
  }
}

</style>